<template>
 <div class="container-fluid bg-light">
      <!-- <h1 class="display-4">JetDock Wisconsin</h1>
        <hr class="my-4"> -->
      <!-- <p class="lead">
        lifts here
        </p> -->
        <h1 class="heading1 text-start">Jet Ski Docks and PWC Lifts</h1>
        <div>
		<p class="text-start">When stored in the water, PWC and jet ski hulls can suffer from delamination and intake fouling. With Jet Dock's floating jet ski docks and PWC lifts, you'll save your craft from undue wear and tear and improve ease of use.</p>                
		<p class="text-start" >Conventional, mechanical boat lift devices are difficult to adapt to personal watercraft. Jet Dock's floating <a href="https://www.jetdock.com/boat-lifts/">PWC lifts</a> solve problems associated with boarding, launching and dry-docking your PWC. Jet Dock is the leader in floating PWC lift and jet ski dock options due to our innovative and customizable solutions. Explore the advantages of our jet ski docks and PWC lifts below.</p>
		<h2 class="text-start">Our PWC Lifts Are Designed for Portability</h2>
		<p class="text-start">Traditional electronic and metal PWC lifts cannot be removed from the water easily and require expensive tools and manpower. They are designed to stay in the water, which can cause extra wear and tear, as well as negatively impact marine life. Jet Dock’s PWC and jet ski lifts are designed to be portable, allowing you to easily transport your PWC and floating PWC lift in the off-season to indoor storage. Uninstalling your jet ski dock is simple, and will help prolong the life of your Jet Dock and PWC.</p>
		<h2 class="text-start">Our Jet Ski Docks Feature a Drive-on Design</h2>
		<p class="text-start">Jet Dock’s jet ski floating docks and PWC lifts are designed with the user in mind. Our PWC lifts feature a simple <a href="https://www.youtube.com/watch?v=djpsriWnTCQ">drive-on</a> design and ample walk-around space, making it easy to board, unboard and <a href="https://www.jetdock.com/gallery-videos.asp">launch your personal watercraft</a>.</p>
		<h2 class="text-start">No Special Tools Required for Installing Your Jet Dock</h2>
		<p class="text-start">The floating PWC lifts and jet ski docks from Jet Dock are easy to install with simple cleats and connectors, which don’t require any special tools. Our PWC and floating jet ski lifts provide you with everything you need to quickly install and enjoy.</p>
		<p class="text-start">No floating PWC and jet ski dock on the market today can deliver the benefits of a Jet Dock. Personal watercrafts aren't intended to be stored in the water forever. Keep them dry with PWC docks or jet ski lifts from Jet Dock!</p>
		<p class="text-start"><router-link to="/contactform">Contact a knowledgeable Jet Dock representative</router-link> today to start the journey to more fun and time on the water.</p>					
        </div>
 </div>
</template>


